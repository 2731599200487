import { urlForImage } from 'utils/imageUtils';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Typography from '@mui/material/Typography';
import YouTubeIcon from '@mui/icons-material/YouTube';
import styled from '@emotion/styled';
import theme from 'theme';

const StyledFooter = styled.div`
  ${theme.breakpoints.down('lg')} {
    padding: ${theme.spacing(6, 2, 10, 2)};
  }
  width: 100%;
  height: auto;
  padding: ${theme.spacing(8, 17.5, 10, 16.5)};
  background-color: #1e1e1e;
`;

const Top = styled.div`
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: ${theme.spacing(2.5)};
    margin-bottom: ${theme.spacing(5.5)};
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(9)};
`;

const Middle = styled.div`
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: ${theme.spacing(9)};
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${theme.spacing(1)};
`;

const Column = styled.div`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    border-bottom: solid 1px #6A6A6A;
    border-right: none;
    padding: ${theme.spacing(3, 0)};
  }
  width: 25%;
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing(3)};
  padding: ${theme.spacing(0, 3)};
  border-bottom: none;
  border-right: solid 1px #6A6A6A;
`;

const PhoneColumn = styled.div`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: ${theme.spacing(3, 0)};
  }
  width: 25%;
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing(3)};
  padding: ${theme.spacing(0, 3)};
`;

const BlockLogo = styled.img(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    max-height: ${theme.spacing(2)};
    width: ${theme.spacing(8)};
  }
  cursor: pointer;
  max-height: ${theme.spacing(3)};
  width: auto;
`);

const Slogan = styled(Typography)`
  ${theme.breakpoints.down('sm')} {
    font-size: 1.25rem;
  }
  font-size: 1.75rem;
  font-weight: 500;
  color: ${theme.palette.primary.contrastText};
  width: auto;
`;

const BottomText = styled(Typography)`
${theme.breakpoints.down('sm')}{
  display: contents;
}
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 200;
  line-height: 1.375rem;
  letter-spacing: 0.07rem;
  color: ${theme.palette.primary.contrastText};
  width: auto;
  cursor: pointer;
`;

const Title = styled(Typography)`
  font-size: 0.938rem;
  font-weight: 600;
  color: ${theme.palette.primary.contrastText};
`;

const TitleLink = styled(Typography)`
  font-size: 0.938rem;
  font-weight: 600;
  color: ${theme.palette.primary.contrastText};
  cursor: pointer;
`;

const Text = styled(Typography)`
  ${theme.breakpoints.down('sm')} {
    font-size: 0.875rem;
  }
  font-size: 0.938rem;
  font-weight: 200;
  color: ${theme.palette.primary.contrastText};
  width: auto;
`;

const TextLink = styled(Typography)`
  ${theme.breakpoints.down('sm')} {
    font-size: 0.875rem;
  }
  font-size: 0.938rem;
  font-weight: 200;
  color: ${theme.palette.primary.contrastText};
  width: auto;
  cursor: pointer;
`;

const StyledIconButton = styled(IconButton)`
  background-color: #fff;
  width: 40px;
  height: 40px;
`;

const Avatar = styled.img`
  height: 85px;
  width: 85px;
`;

interface FooterProps {
  footer: any;
  phone: any;
}

const Footer = ({
  footer,
  phone,
}: FooterProps) => {
  const {
    t,
  } = useTranslation();
  /* const isMobile = useMediaQuery(theme.breakpoints.down('sm')); */
  const router = useRouter();

  const handleLogoClick = useCallback(() => {
    router.push('/');
  }, [router,]);

  const handleNavigationLinkClick = useCallback((link: string) => () => {
    if (link === 'orderServices') {
      router.push('/services');
    } else if (link === 'blog') {
      router.push('/blog');
    } else if (link === 'wayToOperate') {
      router.push('/our-way-to-operate');
    } else if (link === 'joinProvider') {
      router.push('/join-us');
    } else if (link === 'termsOfService') {
      router.push('/terms-of-service');
    } else if (link === 'privacyPolicy') {
      router.push('/privacy-policy');
    }
  }, [router,]);

  return <StyledFooter>
    <Top>
      <BlockLogo onClick={handleLogoClick} src='/images/logos/block_logo_white.svg'></BlockLogo>
      <Slogan>
        {footer.slogan}
      </Slogan>
    </Top>

    <Middle>
      <Column>
        <Grid container rowGap={3}>
          <Grid item>
            <Title>
              {footer.descriptionTitle}
            </Title>
          </Grid>
          <Grid>
            <Typography variant='body1' color={'#fff'}>
              {footer.description}
            </Typography>
          </Grid>
        </Grid>
      </Column>

      <Column>
        <Grid container rowGap={3}>
          <Grid item xs={12}>
            <TitleLink onClick={handleNavigationLinkClick('orderServices')}>
              {t('Order services')}
            </TitleLink>
            <TitleLink onClick={handleNavigationLinkClick('wayToOperate')}>
              {t('Our way to operate')}
            </TitleLink>
            <TitleLink onClick={handleNavigationLinkClick('blog')}>
              {t('Blog')}
            </TitleLink>
          </Grid>

          {/* TODO: Enable after the Pilot */}
          {/* <Grid item xs={12}>
            <TextLink onClick={handleNavigationLinkClick('joinProvider')}>
              {t('Join as a service provider')}
            </TextLink>
            <TextLink onClick={handleNavigationLinkClick('forMedia')}>
              {t('For media')}
            </TextLink>
          </Grid> */}
        </Grid>
      </Column>

      <Column>
        <Grid container rowGap={3}>
          <Grid item>
            <Title>
              {t('Follow us')}
            </Title>
          </Grid>
          <Grid>
            <Typography variant='body1' color={'#fff'}>
              {footer.someDescription}
            </Typography>
          </Grid>

          {/* TODO: Remove this when the social media links work */}
          <Grid>
            <Typography variant='body1' color={'#fff'}>
              {t('Upcoming features')}
            </Typography>
          </Grid>

          {/* TODO: Social media links */}
          <Grid container gap={1}>
            <StyledIconButton color='info'>
              <FacebookOutlinedIcon />
            </StyledIconButton>
            <StyledIconButton color='info'>
              <InstagramIcon />
            </StyledIconButton>
            <StyledIconButton color='info'>
              <TwitterIcon />
            </StyledIconButton>
            <StyledIconButton color='info'>
              <LinkedInIcon />
            </StyledIconButton>
            <StyledIconButton color='info'>
              <YouTubeIcon />
            </StyledIconButton>
          </Grid>
        </Grid>
      </Column>

      {/* Phone services */}
      <PhoneColumn>
        <Grid container rowGap={3}>
          <Grid item>
            <Title>
              {t('Our phone service will help you')}
            </Title>
            <Title>
              {phone.phoneServiceDays} {phone.phoneServiceTimes}
            </Title>
          </Grid>

          <Grid container columnGap={3} alignItems={'center'}>
            <Grid item>
              {
                phone.phoneServiceAvatar ?
                  <Avatar
                    src={urlForImage(phone.phoneServiceAvatar.image).url()}
                    alt={phone.phoneServiceAvatar.alt}
                  /> :
                  <Avatar alt='customer service avatar' src='/images/logos/placeholder_images/asiakaspalvelu.png' />
              }
            </Grid>
            <Grid item>
              <Title>
                {phone.phoneServiceNumber}
              </Title>
              <Text>
                (mpm)
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </PhoneColumn>

    </Middle>

    <Bottom>
      <BottomText>
        © Block 2022
      </BottomText>
      <BottomText>-</BottomText>
      <BottomText onClick={handleNavigationLinkClick('termsOfService')}>
        {t('Terms of Service')}
      </BottomText>
      <BottomText>-</BottomText>
      <BottomText onClick={handleNavigationLinkClick('privacyPolicy')}>
        {t('Privacy Policy')}
      </BottomText>
    </Bottom>
  </StyledFooter>;
};
export default Footer;
